/* Light mode overrides */
.light-mode {

    --blue-1: #fdfdfe;
    --blue-2: #f7f9ff;
    --blue-3: #edf2fe;
    --blue-4: #dfeaff;
    --blue-5: #d0dfff;
    --blue-6: #bdd1ff;
    --blue-7: #a6bff9;
    --blue-8: #87a5ef;
    --blue-9: #3d63dd;
    --blue-10: #3657c3;
    --blue-11: #395bc7;
    --blue-12: #1d2e5c;

    --blue-contrast: #fff;
    --blue-surface: #f5f8ffcc;
    --blue-indicator: #3d63dd;
    --blue-track: #3d63dd;

    --base_text_color: #1d2e5c;
    --base_background: #fdfdfe;

    --button_background: #edf2fe;
    --button_color: #1d2e5c;
    --highlight_element: #87a5ef;
    --border_color: #6978bd;

    --user_input_background: #dfeaff;
    --user_text: #1d2e5c;
    --duck_text_background: #fdfdfe; 
    --duck_text: #1d2e5c;
    --chat-main-background: #d0dfff;
    --code_border_width: 0px;

}


/* Dark mode (default) overrides */
.dark-mode {
  --base_text_color: #d7e0fa;
  --base_background: #20273b;

  --button_background: #242f57;
  --button_color: #d7e0fa;
  --highlight_element: #3a4879;
  --border_color: #47578d;

  --user_input_background: #20273b;
  --user_text: #9cb1f3;
  --duck_text_background: #20273b;
  --duck_text: #d7e0fa;
  --chat-main-background: #141823;
  --code_border_width: 0px;

  --blue-1: #0e111a;
  --blue-2: #141823;
  --blue-3: #1d2540;
  --blue-4: #242f57;
  --blue-5: #2e3b68;
  --blue-6: #3a4879;
  --blue-7: #10131b;
  --blue-8: #5568a9;
  --blue-9: #7b94e8;
  --blue-10: #7088db;
  --blue-11: #9cb1f3;
  --blue-12: #d7e0fa;

  --blue-contrast: #fff;
  --blue-surface: #171f3580;
  --blue-indicator: #7b94e8;
  --blue-track: #7b94e8;

}


:root {
  background-color:  var(--base_background);
}

.citeBox {
  background-color:  var(--user_input_background);
  color: var(--user_text);
}

select {
  cursor: pointer;
  background-color: var(--user_input_background);
  color: var(--base_text_color);
  border: 1px solid var(--border_color);
}

input {
  background-color: var(--user_input_background);
  color: var(--base_text_color);
  border: 1px solid var(--border_color);
}

* {
  margin: 0;
  padding: 1px;
  box-sizing: border-box;
}

.left-column {
  padding: 2px;
}


html, body {
  height: 97vh;
  background-color: var(--base_background);
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.Header {
  display: flex;
  height: 4rem;
  margin-bottom: 1vh;
  justify-content: space-between;
}


.header-link {
  text-decoration: none;
  color: inherit; /* Use the default text color */
}

.syntaxHighlight {
    border: var(--code_border_width) solid var(--border_color);
    border-radius: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: var(--duck_text);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 13rem;
  background-color: var(--button_background);
  color: var(--duck_text);
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  font-size: .9rem;
  opacity: 0;
  transition: opacity 0.3s;
  border: 1px solid var(--border_color);
}


.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.UserInfo {
  column-span: 2;
  gap: 6px;
  display: flex;
}

.ConversationsList ul {
  list-style-type: none;
  padding-left: 0;
}

.ConversationsList li {
  cursor: pointer;
  padding: 5px;
  margin: 2px 0;
}

.ConversationsList li.active {
  font-weight: bold;
  border-radius: 10px;
  background-color: var(--highlight_element);
}

.ConversationsList li:hover {
  border-radius: 10px;
  background-color: var(--highlight_element);
}

.ConversationsList button {
  margin-right: .5rem;
  padding-left: 5px;
  padding-right: 5px;
}


.ConversationsList button:hover {
  background-color: var(--highlight_element);
}

.main-text-input {
  background-color: var(--user_input_background);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: var(--user_text);
  font-size: 16px;
  resize: none;
  width: 100%; /* Adjust the width as a proportion of the container */
  overflow-y: auto; /* Enable scrolling when content overflows */
  border: 1px solid var(--border_color); /* Optional: match border color to the theme */
  border-radius: 6px; /* Optional: add rounded corners */
  padding: 5px; /* Optional: add padding for better readability */
  margin-right: 10px; /* Adjust spacing between textarea and button */
}


.Main {
  min-width: 710px;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--base_text_color);
  background-color: var(--base_background);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.total-view {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
}

.UserQueries {
    width: 12rem;
}

.instructor-layout {
  flex: 1;
  padding-left: 20px;
}

.Chat-main {
  /* max-width: 80%; Set width to 80% of the parent element */
  display: flex-end;
  flex-direction: column;
  flex: 4;
  align-items: center;
  justify-content: flex-end;
  margin-left: 2rem;
  margin-right: 1rem;
}

input {
  padding: 10px;
  margin-right: 10px;
  background-color: var(--user_input_background);
  color: var(--user_text);
}

button {
    padding: 3px;
    color: var(--button_color);
    font-weight: bold;
    background: var(--button_background);
    border-radius: 6px;
    border: 1px solid var(--border_color);
    cursor: pointer;
    justify-content: 'center';
    display:'flex';
    align-items:'center';
}

.Cite {
    align-items: center;
}

.big-button {
  padding: 10px;
  color: var(--button_color);
  font-weight: bold;
  background: var(--button_background);
  border-radius: 6px;
  border: 1px solid var(--border_color);
  cursor: pointer;
}

.roster {
  font-size: small;
}


/* Style for the chat log to enable scrolling and fixed width */
.chat-log {
  height: 68vh;
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
  word-wrap: break-word;
  border: 1px solid var(--border_color); /* Optional: Add a border for visual distinction */
  padding: 10px; /* Optional: Add padding for better appearance */
  box-sizing: border-box; /* Ensure padding does not affect the height */
  margin: auto; /* Center the chat log horizontally */
  border-radius: 6px;
  background-color: var(--chat-main-background);
}


.duck-output {
  text-align: left;
  color: var(--duck_text);
  align-self: flex-start;
  max-width: 95%; /* Ensure it doesn't exceed a certain width */
  word-wrap: break-word; /* Break words if they are too long */
  word-break: break-word; /* Ensures long words break */
  overflow-wrap: break-word; /* Ensures long words wrap to the next line */
  border-radius: 10px;
  background: var(--duck_text_background);
  padding: 20px;
  padding-bottom: 0px;
  /* padding-left: 40px; */
  margin-top: 3px;
  margin-bottom: 3px;
}

.duck-output ul,
.duck-output ol {
  padding-bottom: 1rem;
  padding-left: 30px; 
}

.duck-output p, .duck-output h1, .duck-output h2,
.duck-output h3, .duck-output h4, .duck-output pre {
  padding-bottom: 1rem;
}

.user-input {
  display: inline-block; /* Make sure the container sizes based on its content */
  max-width: 80%; /* Maximum width as a percentage of the parent element */
  word-wrap: break-word; /* Ensure words break properly within the container */
  padding: 0px 10px;
  color: var(--user_text);
  text-align: left;
  align-self: flex-end;
  border-radius: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  background: var(--user_input_background);
}

/* Applies to duck and user dialogue */
.chat-entry {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: flex-end; /* Align items to the right */
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}


/* Form at bottom of chat: */
.Entry {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20vh;
  margin-top: 3px;
  padding: 0;
}

.Entry form {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 10px;
}

.main-text-input {
  height: 18vh;
  width: 100%;
  margin-right: 10px;
}


::-webkit-scrollbar-button {
    height: 0;
    width: 0;
 }

/* Total scrollbar width */
::-webkit-scrollbar {
    width: 12px; /* Scrollbar width */
    height: 12px; /* For horizontal scrollbars */
  }
  
  /* Track (background of the scrollbar) */
  ::-webkit-scrollbar-track {
    background: var(--button_color); /* Light gray background */
    border-radius: 10px; /* Rounded corners */
  }
  
  /* Handle (draggable part of the scrollbar) */
  ::-webkit-scrollbar-thumb {
    background: var(--base_background); 
    border-radius: 10px; /* Rounded corners */
  }
  
  /* Handle hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--highlight_element); 
  }

  ::-moz-scrollbar-button, ::-webkit-scrollbar-button {
    width: 0px;
  }

  html {
    scrollbar-width: thin; /* Options: auto | thin | none */
    scrollbar-color: var(--button_color) var(--base_background);
  }
  
  /* Report bug css */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    resize: none;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modal button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .modal .error-message {
    color: red;
    margin-top: 10px;
  }
  